<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
          <a
            @click="back"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate">Jenis Produk</span>
          </span>
          <div class="text-nowrap order-md-last">
            <a id="shareBtn" class="btn px-0 d-inline-block mr-3" @click="shareData">
              <i class="fa fa-share-alt text-light"></i>
            </a>
            <router-link
              :to="'/jual/produk-jenis/edit/'+state.id"
              class="btn px-0 d-inline-block"
            >
              <i class="fas fa-pencil-alt text-light"></i>
            </router-link>
          </div>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col pb-5 with-fixed-sidebar">
          <div
            class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin"
            :style="'background-image: url('+isPhoto(state.data.photo)+');'"
          >
            <div class="container text-center">
              <p class="h5 my-2 text-white">{{ state.data.name }}</p>
            </div>
          </div>
          <div class="row mt-3 mb-2">
            <div class="col-12 d-none">
              <select
                class="form-control form-control-sm"
                id="selFilter"
                required
              >
                <option value="">Semua produk...</option>
                <option value="aktif" selected>Aktif</option>
                <option value="inaktif">Inaktif</option>
              </select>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <form @submit.prevent="searchData">
            <div class="input-group input-group-sm my-3">
                <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Cari..."
                v-model="state.search" />
                <span class="input-group-append">
                    <button type="submit" class="btn btn-outline-main"><i class="fa fa-search"></i
                ></button>
                </span>
                </div>
            </div>
          </form>

          <div class="small text-truncate">Daftar Produk untuk {{ state.data.name }}:</div>

          <div id="resultList" class="row mt-3 mb-5" v-if="state.exist">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3" v-for="dt in state.produks" :key="dt.id">
              <div class="card mb-2">
                <div class="card-body p-0">
                  <div class="float-left w-25 border-right">
                    <div
                      class="cropped"
                      :style="'background-image: url('+state.url + dt.photo +');'"
                    ></div>
                  </div>
                  <div class="float-right w-75 pt-2 pl-3">
                    <router-link
                      :to="'/jual/produk/info/'+dt.id"
                      class="
                        card-title
                        text-main
                        font-weight-bold
                        d-block
                        mb-1
                        mt-1
                        card-link
                        stretched-link
                        text-truncate
                      "
                      >{{ dt.name }}</router-link>
                    <p class="card-text mb-1 text-truncate small">
                      <span class="currency">Rp. {{ changeMoney(dt.price) }}</span>
                    </p>
                    <p class="card-text mb-0 text-truncate">
                      <span class="badge mr-2" :class="dt.status === 1? 'badge-secondary':'badge-danger'">{{ dt.status === 1? 'Aktif':'InAktif'}}</span>
                      <small class="text-muted">Terjual: 141 pcs</small>
                    </p>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
            <!-- .col -->
          </div>
          <router-link
            to="/jual/produk/tambah"
            id="btn-float"
            class="
                bg-warning
                position-fixed
                rounded-circle
                shadow
                text-dark text-center
            "
            >
            <div class="d-flex justify-content-center mt-3">
                <i class="fas fa-plus" style="font-size: 25px;"></i>
            </div>
        </router-link>
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import { reactive, onMounted } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import imageDefault from '../../../../assets/img/img.png';
import Share from '../../../../components/social_media/Share';

export default {
  setup() {
    const route = useRoute();
    const state = reactive({
      id: '',
      data: {},
      exist: false,
      search: '',
      url: process.env.VUE_APP_API_URL,
      produks: {},
    })
    const loadData = async() => {
      await axios.get('/api/seller/produk-category/'+state.id).then((res) => {
        if(res.data.success){
          state.data = res.data.data;
          if(res.data.produk.length > 0){
            state.produks = res.data.produk;
            state.exist = true;
          }
        }
      })
    }
    onMounted(() => {
      state.id = route.params.id;
      loadData();
    })
    const isPhoto = (dt) => {
        if(dt){
            return state.url + dt;
        }else {
            return imageDefault;
        }
    }
    const searchData = () => {
      if(state.search){
        axios.get('/api/seller/produk-category/'+state.id+'?search='+state.search).then((res) => {
          if(res.data.success){
            state.data = res.data.data;
            if(res.data.produk.length > 0){
              state.produks = res.data.produk;
              state.exist = true;
            }else{
              state.produks = {};
              state.exist = false;
            }
          }
        })
      }
    }
    const changeMoney = (dt) => {
        let cur = Math.ceil(Number(dt));
        let mo = (cur).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        let ret = mo.split(".")[0]; 
        return ret; 
    }
    const shareData = async () => {
      //share
      const url = process.env.VUE_APP_HOME_URL + '/home/pcategory/'+state.data.key;
      let dt = {
        title: state.data.name,
        description: "",
        url: url,
      }
      Share.run('whatsapp', dt);

    }
    const back = () => {
      window.history.back();
    }
    return {
      state,
      searchData,
      isPhoto,
      changeMoney,
      shareData,
      back
    }
  },
}
</script>